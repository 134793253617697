/**
 * @license
 * Copyright 2018 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-layer-side-panel {
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: stretch;
  max-width: 80%;
}

.neuroglancer-managed-user-layer-info-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}

.neuroglancer-layer-info-panel-container {
  flex: 1;
}


.neuroglancer-layer-side-panel-title {
  display: flex;
  flex-direction: row;
  font-family: sans-serif;
  font-size: 10pt;
  background-color: #393;
  padding: 2px;
}

.neuroglancer-layer-side-panel-title > input {
  flex: 1;
  background-color: transparent;
  border: 0px;
  color: white;
  outline: 0px;
}

.neuroglancer-layer-side-panel-info-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  font: 10pt sans-serif;
}

.neuroglancer-layer-side-panel-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.neuroglancer-layer-side-panel-type-measure {
  position: absolute;
  visibility: hidden;
  top: 0px;
  height: auto;
  width: auto;
  white-space: nowrap;
}

.neuroglancer-layer-side-panel-type,
.neuroglancer-layer-side-panel-type-measure {
  padding-left: 3px;
  padding-right: 3px;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 10pt;
  box-sizing: border-box;
  
}

.neuroglancer-layer-side-panel-type {
  -webkit-appearance: none;
  color: white;
  cursor: pointer;
  background-color: #030;
  margin-right: 5px;
  border: 0px;
  outline: 0px;
}

.neuroglancer-layer-side-panel-type option {
  background-color: #000;
}
