/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-annotation-point-list-dropdown {
  display: flex;
}

.neuroglancer-annotation-shader-property {
  white-space: pre;
  font-family: monospace;
  font-size: medium;
}


.neuroglancer-annotation-shader-property-type {
  color: #c6c;
  margin-right: 1ch;
}

.neuroglancer-annotation-shader-property-identifier {
  user-select: text;
}

.neuroglancer-annotation-shader-property-identifier::after {
  content: "()";
  color: #999;
}
