/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

html, body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
}

#neuroglancer-container {
  width: 100vw;
  flex: 1;
}

#ontcontainer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(40,40,46, 0.7);
border-radius: 3px;
height: 25px;
max-width: 75%;
}
#onttext {
  padding: 5px;
  font-size: 12px;
  font-family: "Gentona Bold", "Arial Bold", sans-serif;
  font-weight: normal !important;
}
