/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.segment-set-widget button {
  margin: 3px;
}

.segment-set-widget {
  flex: 1;
  overflow-y: auto;
  height: 0px;
  min-height: 0px;
  flex-basis: 0px;
}

.segment-set-widget .item-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
