/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-multiline-autocomplete {
  margin-top: 2px;
  padding: 2px;
  position: relative;
  background-color: #222;
}

.neuroglancer-multiline-autocomplete-hint, .neuroglancer-multiline-autocomplete-input {
  font-family: monospace;
  color: #fff;
  word-wrap: break-word;
}

.neuroglancer-multiline-autocomplete-input:focus {
  outline: 0px;
}

.neuroglancer-multiline-autocomplete-input:not(:focus):empty::before {
  content: attr(data-placeholder);
  color: #aaa;
}

.neuroglancer-multiline-autocomplete-hint {
  color: #aaa;
}

.neuroglancer-multiline-autocomplete-hint, .neuroglancer-multiline-autocomplete-input, .neuroglancer-multiline-autocomplete-completion {
  font-family: monospace;
  font-size: medium;
}

.neuroglancer-multiline-autocomplete-dropdown {
  color: #fff;
  background-color: #181818;
  position: absolute;
  display: block;
  outline: 0;
  margin: 0;
  padding: 0;
  left: 0px;
  right: 0px;
  text-align: left;
  cursor: default;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  overflow-y: scroll;
  word-wrap: break-word;
}

.neuroglancer-multiline-autocomplete-completion:nth-child(even):not(.neuroglancer-multiline-autocomplete-completion-active) {
  background-color: #2b2b2b;
}

.neuroglancer-multiline-autocomplete-completion:hover {
  outline: 1px solid #ddd;
}

.neuroglancer-multiline-autocomplete-completion-active {
  background-color: #666;
}

.neuroglancer-multiline-autocomplete-completion-with-description {
}

.neuroglancer-multiline-autocomplete-completion-description {
  font-style: italic;
  color: #f9f;
}
