/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-layer-panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: black;
  align-items: stretch;
  margin-left: -5px;
  margin-top: -1px;
}

.neuroglancer-layer-item {
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  font-family: sans-serif;
  font-size: 10pt;
  background-color: black;
  padding: 1px;
}

.neuroglancer-layer-item, .neuroglancer-layer-add-button {
  margin: 1px;
  margin-left: 5px;
}

.neuroglancer-layer-item[layer-selected=true] {
  border-color: #8c8;
}

.neuroglancer-layer-item[layer-selected=true]:hover {
  border-color: #3c3;
}


.neuroglancer-layer-item:hover {
  border-color: #daa520;
}

.clear-button::after {
  content: "x";
}

.neuroglancer-layer-dropdown {
  flex-direction: column;
  background-color: black;
  padding: 5px;
  border: 1px solid white;
  box-sizing: border-box;
}

.neuroglancer-layer-item-label {
  display: inline-block;
  background-color: #222;
  padding-right: 3px;
}

.neuroglancer-layer-item-number {
  display: inline-block;
  background-color: /*#daa520*/ #9a7518;
  font-weight: bold;
  padding: 1px;
}

.neuroglancer-layer-panel[data-show-hover-values="true"] .neuroglancer-layer-item-value {
  display: inline-block;
  font-family: monospace;
  font-size: medium;
  max-width: 50ch;
  margin-left: 1ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipses;
  text-align: center;
}

.neuroglancer-layer-panel[data-show-hover-values="false"] .neuroglancer-layer-item-value { 
  display:none;
}

.neuroglancer-layer-item[layer-visible=false] .neuroglancer-layer-item-label {
  text-decoration: line-through;
}

.neuroglancer-layer-item[layer-visible=false] {
  color: #bbb;
}

.neuroglancer-layer-panel-drop-zone {
  display: inline-block;
  flex: 1;
}
