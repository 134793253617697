/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-single-mesh-dropdown .neuroglancer-single-mesh-attribute-widget {
  max-height: 6em;
  margin-bottom: 8px;
}

.neuroglancer-single-mesh-dropdown .neuroglancer-shader-code-widget {
  height: 6em;
  width: 60ch;
  border: 1px solid transparent;
}

.neuroglancer-single-mesh-dropdown-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.neuroglancer-single-mesh-shader-overlay .neuroglancer-shader-code-widget {
  width: 80vw;
  height: 80vh;
}

.neuroglancer-single-mesh-attribute-widget {
  word-wrap: break-word;
  overflow-y: auto;
  display: grid;
  grid-template-columns: [type] auto [name] auto [range] auto;
}

.neuroglancer-single-mesh-layer-shader-overlay .neuroglancer-single-mesh-attribute-widget {
  max-height: 20vh;
}

.neuroglancer-single-mesh-attribute {
  font-family: monospace;
  display: contents;
}

.neuroglancer-single-mesh-attribute-type {
  color: #c6c;
  grid-column: type;
}

.neuroglancer-single-mesh-attribute-name {
  margin-left: 1ch;
  color: white;
  grid-column: name;
  user-select: text;
}

.neuroglancer-single-mesh-attribute-range {
  grid-column: range;
  margin-left: 1ch;
  white-space: nowrap;
  color: #999;
  grid-column: range;
}
