/**
 * @license
 * Copyright 2018 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-mouse-selection-tooltip {
  color: black;
  background-color: white;
  padding: 2px;
  border: 1px solid black;
  font-family: sans-serif;
  font-size: 10pt;
}

.neuroglancer-mouse-selection-tooltip-title {
  font-weight: bold;
}

.neuroglancer-mouse-selection-tooltip-description {
}

.neuroglancer-annotation-tooltip-property-label, .neuroglancer-annotation-tooltip-relationship-label {
  margin-right: 5px;
}
