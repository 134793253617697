/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.image-dropdown .neuroglancer-shader-code-widget {
  height: 8em;
  /* width: 60ch; */
  border: 1px solid transparent;
}

.image-dropdown-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .image-dropdown-top-row .range-slider {
  flex: 1;
} */

.image-layer-shader-overlay .neuroglancer-shader-code-widget {
  width: 80vw;
  height: 80vh;
}

.neuroglancer-segmentation-dropdown-skeleton-shader-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
