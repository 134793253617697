/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-statistics-panel {
  overflow-x: auto;
  overflow-y: auto;
}

.neuroglancer-statistics-panel > table > thead td {
  position: sticky;
  top: 0px;
  zIndex: 2;
  background-color: black;
}

.neuroglancer-statistics-panel > table > tbody tr:hover {
  background-color: #333;
}
