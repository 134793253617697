
/**
 * @license
 * Copyright 2019 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-resize-gutter-vertical {
  height: 1px;
  background-color: #333;
  background-clip: content-box;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: row-resize;
}

.neuroglancer-resize-gutter-horizontal {
  width: 1px;
  background-color: #333;
  background-clip: content-box;
  padding-right: 2px;
  padding-left: 2px;
  cursor: col-resize;
}
