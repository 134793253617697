/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.describe-key-bindings {
  overflow-x: hidden;
}

.describe-key-bindings-container {
  display: grid;
  overflow-y: scroll;
  max-height: 80vh;
  overflow-x: hidden;
  grid-template-columns: [dt-start] 0fr [dt-end] 1em [dd-start] 1fr [dd-end];
  grid-auto-rows: auto;
}

.describe-key-bindings-container h2 {
  grid-column-start: 1;
  grid-column-end: -1;
}

.describe-key-bindings .dt {
  grid-column: dt-start;
  font-family: monospace;
  font-weight: bold;
  display: inline-block;
}

.describe-key-bindings .dd {
  grid-column: dd-start;
  display: inline-block;
}
