/**
 * @license
 * Copyright 2018 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-tab-view {
  display: flex;
  flex-direction: column;
}

.neuroglancer-tab-view-bar {
  display: block;
  background-color: #333;
  border-bottom: 1px solid white;
}

.neuroglancer-tab-label {
  display: inline-block;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: none;
  margin-right: 4px;
  padding-top: 1px;
  padding-left: 2px;
  padding-right: 2px;
  font: 10pt sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.neuroglancer-tab-label:hover {
  color: #daa520;
}

.neuroglancer-tab-label.neuroglancer-selected-tab-label {
  background-color: black;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid black;
  padding-bottom: 1px;
  margin-bottom: -1px;
}

.neuroglancer-stack-view {
  display: contents;
}

.neuroglancer-tab-view > .neuroglancer-stack-view > .neuroglancer-tab-content {
  flex: 1;
  flex-basis: 0px;
  height: 0px;
  min-height: 0px;
  padding: 2px;
}
