/**
 * @license
 * Copyright 2017 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-context-menu {
  position: absolute;
  z-index: 100;
  border: 1px solid white;
  background-color: black;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.5);
  font-family: sans-serif;
  font-size: 11pt;
  padding: 5px;
  color: white;
  outline: 0px;
}
